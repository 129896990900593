<template>
    <div>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" icon-prefix="al-icon" :title_icon="my_nav_bar_icon |filter_icon"></nav-bar-component>
        <van-grid clickable :column-num="1" :gutter="15">
            <van-grid-item
                v-for="v in tabbar_arr[active].children"
                :key="v.name"
                :text="v.name"
                icon-prefix="al-icon"
                :icon="v.icon | filter_icon"
                :badge="v.badge"
                :url="v.url"
                :to="v.to"
            />
        </van-grid>
        <tabbar-component :tabbar_arr="tabbar_arr" @clk="tabbar_clk"></tabbar-component>
    </div>
</template>

<script>
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import TabbarComponent from '@/components/common/TabbarComponent.vue'

export default {
    name: 'IndexView',
    data() {
        return {
            active: this.$store.state.active,
            my_nav_bar_name: this.$store.state.my_nav_bar_name,
            my_nav_bar_icon: this.$store.state.my_nav_bar_icon,
            tabbar_arr: [
                {
                    name: '登记管理',
                    icon: 'al-icon-yuyuefuwu',
                    children: [
                        {
                            name: '在线登记',
                            icon: 'al-icon-wodeyuyue',
                            badge: '',
                            url: '',
                            to: '/CarDriverAppointAdd'
                        },
                        {
                            name: '车辆管理',
                            icon: 'al-icon-dahuoche--',
                            badge: '',
                            url: '',
                            to: '/CarDriverCarIndex'
                        },
                        {
                            name: '登记记录',
                            icon: 'al-icon-yuyue',
                            badge: '',
                            url: '',
                            to: '/CarDriverAppointIndex'
                        },
                    ]
                },
                {
                    name: '个人资料',
                    icon: 'al-icon-icon_wode',
                    children: [
                        {
                            name: '我的信息',
                            icon: 'al-icon-gerenxinxi',
                            badge: '',
                            url: '',
                            to: '/UserInformation'
                        },
                        {
                            name: '修改资料',
                            icon: 'al-icon-shouji',
                            badge: '',
                            url: '',
                            to: '/UserEdit'
                        },
                        {
                            name: '修改证件照片',
                            icon: 'al-icon-zhengjian',   
                            badge: '',
                            url: '',
                            to: '/UserCard'
                        }
                    ]
                }
            ]
        }
    },
    computed: {},
    methods: {
        tabbar_clk(a) {
            this.active = a
            this.my_nav_bar_name = this.tabbar_arr[a].name,
            this.my_nav_bar_icon = this.tabbar_arr[a].icon
            let obj = {}
            obj = {
                active: a,
                my_nav_bar_name: this.tabbar_arr[a].name,
                my_nav_bar_icon: this.tabbar_arr[a].icon
            }
            this.$store.commit('edit_active',obj)
        }
    },
    filters: {
        filter_icon(v) {
            const i = v.split('al-icon-')
            return i[1]
        }
    },
    props: {
        nav_bar: {
            type: Object,
            default() {
                return {
                    left: false,
                    right: {
                        type: '',
                        name: ''
                    },
                    fixed: false,   //固定
                    placeholder: false  //等高占位
                }
            }
        },
        nav_bar_name: {
            type: String,
            default() {
                return ''
            }
        },
        nav_bar_icon: {
            type: String,
            default() {
                return ''
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        NavBarComponent,
        TabbarComponent
    },
    watch: {
        nav_bar_name(newVal) {
            this.my_nav_bar_name = newVal
        },
        nav_bar_icon(newVal) {
            this.my_nav_bar_icon = newVal
        }
    },
}
</script>

<style>
.van-grid {
    margin-top: 0.3rem;
}
.van-grid-item__content {
    box-shadow:0 0 10px #C4E1FF;
    border-radius:5%
}
.van-grid-item__icon {
    color: #B15BFF
}
</style>